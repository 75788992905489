var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fc-user-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("人员信息管理")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-card",
            { staticClass: "table-query-card", attrs: { title: "查询" } },
            [
              _c("jtl-table-query-component", {
                ref: _vm.JTL.CONSTANT.DEFAULT_TABLE_QUERY_NAME,
              }),
            ],
            1
          ),
          _c(
            "a-card",
            { attrs: { title: "列表" } },
            [
              _c(
                "div",
                {
                  staticClass: "table-header-button",
                  attrs: { slot: "extra" },
                  slot: "extra",
                },
                [
                  _c(
                    "jtl-button",
                    {
                      attrs: { "table-header-child": "", type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.addClick(new _vm.FcUserEntityModel())
                        },
                      },
                    },
                    [_vm._v("+ 信息录入")]
                  ),
                  _c(
                    "jtl-button",
                    {
                      attrs: { "table-header-child": "", type: "primary" },
                      on: { click: _vm.acidRecordClick },
                    },
                    [_vm._v("+ 批量添加核酸记录")]
                  ),
                  _c(
                    "jtl-button",
                    {
                      attrs: { "table-header-child": "", type: "primary" },
                      on: { click: _vm.updateBatchClick },
                    },
                    [_vm._v("批量修改")]
                  ),
                  _c(
                    "jtl-button",
                    {
                      attrs: { "table-header-child": "", type: "primary" },
                      on: { click: _vm.exportExcelClick },
                    },
                    [_vm._v("批量导出")]
                  ),
                ],
                1
              ),
              _c("a-table", {
                attrs: {
                  loading: _vm.listLoading,
                  columns: _vm.tableColumns,
                  "data-source": _vm.listData,
                  pagination: _vm.Pagination,
                  scroll: _vm.tableScroll,
                  "row-key": _vm.TableRowKey,
                  size: _vm.TableSize,
                  "row-selection": {
                    selectedRowKeys: _vm.selectedRowKeys,
                    onChange: _vm.onSelectChange,
                    onSelect: _vm.onSelect,
                  },
                },
                on: { change: _vm.tableChange },
                scopedSlots: _vm._u([
                  {
                    key: "name",
                    fn: function (text, record) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "jtl-link",
                            on: {
                              click: function ($event) {
                                return _vm.editClick(record)
                              },
                            },
                          },
                          [_vm._v(_vm._s(text))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "action",
                    fn: function (text, record) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "jtl-edit-link",
                            on: {
                              click: function ($event) {
                                return _vm.editClick(record)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a",
                          {
                            staticClass: "jtl-edit-link",
                            on: {
                              click: function ($event) {
                                return _vm.acidRecordClick(
                                  record,
                                  _vm.ViewModeType.UPDATE
                                )
                              },
                            },
                          },
                          [_vm._v("核酸记录")]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: "确认删除?",
                              "ok-text": "确认",
                              "cancel-text": "取消",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.deleteClick(record)
                              },
                            },
                          },
                          [
                            _c("a", { staticClass: "jtl-del-link" }, [
                              _vm._v("删除"),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.formDialogOK },
      }),
      _c("QuarantineRoomImportComponent", { ref: "importExcelDialog" }),
      _c("QuarantineRoomExportComponent", { ref: "exportExcelDialog" }),
      _c("AcidRecordComponent", {
        ref: "acidRecordDialog",
        on: { dialogOK: _vm.getList },
      }),
      _c("FcUserBatchUpdateComponent", {
        ref: "userBatchUpdateDialog",
        on: { dialogOK: _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }