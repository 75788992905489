
































































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { FcUserEntityModel, FcUserQueryModel } from '@common-src/entity-model/fc-user-entity';
import FcUserService from '@common-src/service/fc-user';
import QuarantineRoomImportComponent from './quarantine-room-import.vue';
import QuarantineRoomExportComponent from './quarantine-room-export.vue';
import AcidRecordComponent from './acid-record-dialog.vue';
import FcUserBatchUpdateComponent from './fc-user-batch-update.vue';
import { download } from '@common-src/service/request';
import { ViewModeType } from '@common-src/model/enum';

@Component({
    components: {
        QuarantineRoomImportComponent,
        QuarantineRoomExportComponent,
        AcidRecordComponent,
        FcUserBatchUpdateComponent
    }
})
export default class FcUserListComponent extends TableDialogFormComponent<FcUserEntityModel, FcUserQueryModel> {
    FcUserEntityModel = FcUserEntityModel;
    ViewModeType = ViewModeType;

    created() {
        this.initTable({
            service: FcUserService,
            queryModel: new FcUserQueryModel(),
            tableColumns: FcUserEntityModel.getTableColumns()
        });
        this.getList();
    }

    updateBatchClick() {
        const ids = this.getSelectedRowIds();
        if (!ids || ids.length === 0) {
            this.showMessageWarning('请先选择人员信息');
            return;
        }
        (this.$refs.userBatchUpdateDialog as FcUserBatchUpdateComponent).dialogOpen(ids);
    }

    acidRecordClick(model: FcUserEntityModel, viewModeType = ViewModeType.NEW) {
        if (viewModeType === ViewModeType.UPDATE) {
            (this.$refs.acidRecordDialog as AcidRecordComponent).dialogOpen(viewModeType, model);
        } else {
            const ids = this.getSelectedRowIds();
            if (!ids || ids.length === 0) {
                this.showMessageWarning('请先选择人员信息');
                return;
            }
            (this.$refs.acidRecordDialog as AcidRecordComponent).dialogOpen(viewModeType, ids);
        }
    }

    exportExcelClick() {
        (this.$refs.exportExcelDialog as QuarantineRoomExportComponent).dialogOpen();
    }

    async getExcelTemplate() {
        const url = `${BIBIZ_BASE_REQUEST_PATH}/epidemicPreventionControlSystem/quarantineRoom/template`;
        const res = await download(url, null, 'POST');
        this.showMessageSuccess('下载模板成功');
    }

    importExcelClick() {
        (this.$refs.importExcelDialog as QuarantineRoomImportComponent).dialogOpen();
    }
}

