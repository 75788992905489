

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ViewModeType } from '@common-src/model/enum';
import { FormControlType } from '@common-src/model/form-control';
import { post } from '@common-src/service/request';
import { batchUpdateAcidRecord, getRecordList, saveRecordList } from '@common-src/service/fc-user';
import { FcUserEntityModel } from '@common-src/entity-model/fc-user-entity';

@Component
export default class AcidRecordComponent extends FormDialogComponent<any> {
    isProtocol: boolean;
    ids: Array<string>;
    dataModel: FcUserEntityModel;

    async dialogOpen(viewModeType: ViewModeType, params: any) {
        this.dialogVisible = true;
        this.viewMode = viewModeType;
        let recordList = [''];
        if (this.viewMode === ViewModeType.NEW) {
            this.dialogTitle = '批量添加核酸记录';
            this.ids = params;
        } else {
            this.dialogTitle = '核酸记录编辑';
            this.dataModel = params;
            this.startFullScreenLoading();
            const res = await getRecordList(this.dataModel.id).finally(() => {
                this.stopFullScreenLoading();
            });
            recordList = res && res.length > 0 ? _.map(res, item => item.content) : [''];
        }
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItems = [
                    {
                        key: 'content',
                        label: '核酸记录',
                        type: FormControlType.ARRAY_STRING,
                        value: recordList
                    }
                ];
                (this.jtlForm as any).initFormByControls(controlItems);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        this.startFullScreenLoading('正在保存，请耐心等待......');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                if (this.viewMode === ViewModeType.NEW) {
                    const params = {
                        ids: this.ids,
                        content: _.filter(this.jtlForm.formModel.content, item => item)
                    };
                    batchUpdateAcidRecord(params).then(res => {
                        this.showMessageSuccess('批量保存核酸记录成功');
                        this.dialogClose();
                        this.$emit('dialogOK');
                        resolve(null);
                    }).catch(err => {
                        reject();
                        throw err;
                    });
                } else {
                    const params = {
                        id: this.dataModel.id,
                        recordList: _.map(this.jtlForm.formModel.content, item => ({ content: item }))
                    };
                    saveRecordList(params).then(res => {
                        this.showMessageSuccess('保存核酸记录成功');
                        this.dialogClose();
                        this.$emit('dialogOK');
                        resolve(null);
                    }).catch(err => {
                        reject();
                        throw err;
                    });
                }
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
