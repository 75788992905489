

















import { Component } from 'vue-property-decorator';
import FormDialogComponent from '@common-src/mixins/form-dialog-component';
import { ViewModeType } from '@common-src/model/enum';
import { FormControlType } from '@common-src/model/form-control';
import { post } from '@common-src/service/request';
import { NUMBER_INT_REGEXP } from '@common-src/model/regexp';
import { batchUpdateUser } from '@common-src/service/fc-user';

@Component
export default class FcUserBatchUpdateComponent extends FormDialogComponent<any> {
    isProtocol: boolean;
    ids: Array<string>;

    dialogOpen(ids: Array<string>): void {
        this.ids = ids;
        this.dialogVisible = true;
        this.viewMode = ViewModeType.NEW;
        this.dialogTitle = '批量修改';
        this.$nextTick(() => {
            if (this.jtlForm) {
                const controlItems = [
                    {
                        key: 'quarantineStart',
                        label: '开始隔离日期',
                        type: FormControlType.SELECT_DATE,
                        showTime: true
                    },
                    {
                        key: 'quarantineEnd',
                        label: '应隔离日期',
                        type: FormControlType.SELECT_DATE,
                        showTime: true
                    },
                    {
                        key: 'actualQuarantineEnd',
                        label: '实际离舱日期',
                        type: FormControlType.SELECT_DATE,
                        showTime: true
                    },
                    {
                        key: 'drawBloodNum',
                        label: '抽血次数',
                        type: FormControlType.NUMBER,
                        pattern: NUMBER_INT_REGEXP,
                        min: 0
                    }
                ];
                (this.jtlForm as any).initFormByControls(controlItems);
            }
        });
    }

    dialogOK(): any {
        if (!this.jtlForm) {
            return;
        }
        this.startFullScreenLoading('正在批量修改，请耐心等待......');
        return new Promise((resolve, reject) => {
            this.jtlForm.submitForm().then((ret) => {
                const params = {
                    ids: this.ids,
                    quarantineStart: this.jtlForm.formModel.quarantineStart?.toDate().getTime(),
                    quarantineEnd: this.jtlForm.formModel.quarantineEnd?.toDate().getTime(),
                    actualQuarantineEnd: this.jtlForm.formModel.actualQuarantineEnd?.toDate().getTime(),
                    drawBloodNum: this.jtlForm.formModel.drawBloodNum
                };
                batchUpdateUser(params).then(res => {
                    this.showMessageSuccess('批量修改成功');
                    this.dialogClose();
                    this.$emit('dialogOK');
                    resolve(null);
                }).catch(err => {
                    reject();
                    throw err;
                });
            }).catch(err => {
                reject();
                throw err;
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
